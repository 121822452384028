<template>
  <div
    class="group relative text-white rounded-lg overflow-hidden aspect-1 cursor-pointer bg-cover bg-center bg-no-repeat"
    :style="`background-image: url('${media.signedThumbnailUrl}')`"
  >
    <div
      class="absolute h-full inset-0 px-3 py-2 rounded-lg overflow-hidden bg-black bg-opacity-60 flex flex-col items-center hover:bg-opacity-40"
      :class="{
        'bg-opacity-70 bg-primary-600': selected,
      }"
    >
      <div class="w-full flex flex-row items-center justify-between">
        <div class="flex flex-row p-2 -m-2" @click="toggleSelection">
          <font-awesome-icon
            v-if="selected"
            icon="fa-solid fa-circle-dot"
            class="pt-1"
          />
          <font-awesome-icon v-else icon="fa-light fa-circle" class="pt-1" />
        </div>

        <font-awesome-icon
          icon="fa-light fa-magnifying-glass"
          class="pt-1"
          @click="toggleDetails"
        />
      </div>

      <div
        class="h-full w-full flex items-center justify-center"
        @click="toggleSelection"
      >
        <font-awesome-icon
          v-if="isVideo"
          icon="fa-solid fa-play"
          class="text-lg bg-black rounded-full p-4 bg-opacity-85 aspect-1"
        />
      </div>

      <div v-if="pending" class="w-full">
        <div
          title="rejectedMessage"
          class="relative w-full flex flex-row items-center gap-1 bg-black/80 text-white rounded-lg p-1.5 text-xs text-center"
        >
          <div class="w-full leading-none">In Review</div>
        </div>
      </div>

      <div v-if="userTagsNeeded" class="w-full">
        <div
          title="User Tags Needed"
          class="relative w-full flex flex-row items-center gap-1 bg-black/80 text-white rounded-lg p-1.5 text-xs text-center"
        >
          <div class="w-full leading-none">User Tags Needed</div>
        </div>
      </div>

      <div v-if="rejected" class="w-full">
        <div
          title="rejectedMessage"
          class="relative w-full flex flex-row items-center gap-1 bg-primary-600 text-white rounded-lg p-1.5 text-xs text-center"
        >
          <font-awesome-icon
            icon="fa-solid fa-circle-info"
            class="text-white"
          />
          <div class="w-full leading-none">Rejected</div>
        </div>
      </div>

      <div v-if="isVideo" class="w-full flex flex-row justify-end text-xxs">
        {{ $secondsToTime(media.durationInSeconds) }}
      </div>
    </div>
  </div>

  <UModal
    v-model="detailsShowing"
    :ui="{
      fullscreen: 'm-[1rem] h-[90vh] w-[90vw] rounded-2xl',
      shadow: 'shadow-none',
      container: 'items-start md:items-center',
      background: 'bg-black',
    }"
    fullscreen
  >
    <div
      class="h-full w-full flex flex-col justify-between text-white rounded-lg overflow-hidden"
    >
      <div class="flex flex-row items-center justify-between z-10 p-4">
        <span class="text-sm">
          {{ $dayjs(new Date(media.createdAt)).format("MMM D, YYYY @ h:mmA") }}
        </span>
        <div class="flex flex-row items-center gap-2">
          <Button theme="primary" @click="$emit('edit')">
            <font-awesome-icon icon="fa-light fa-pencil" />
            Edit
          </Button>
          <Button
            theme="ghost"
            size="lg"
            iconOnly="true"
            shape="rounded"
            @click="detailsShowing = false"
          >
            <font-awesome-icon icon="fa-light fa-xmark" />
          </Button>
        </div>
      </div>

      <div
        class="w-full h-full flex flex-col sm:flex-row items-center justify-center overflow-hidden"
      >
        <div
          class="flex items-center justify-center max-w-full max-h-full relative p-4"
        >
          <img
            v-if="isImage"
            :src="media.signedUrl"
            class="max-h-[calc(90vh-8rem)] max-w-full object-contain"
          />
          <VideoPlayer
            v-if="isVideo"
            :src="media.signedUrl"
            class="max-h-[calc(90vh-8rem)] max-w-full object-contain"
          />
        </div>

        <div
          v-if="media.status == 'UserTagsNeeded'"
          class="flex flex-col gap-3 p-3"
        >
          <p class="font-semibold">
            Tag the other people who appear in your upload.
          </p>

          <UFormGroup>
            <template #description>
              <p v-if="numberOfFacesToTag > 1">
                {{ numberOfFacesToTag - creatorsToTag.length }}
                {{
                  numberOfFacesToTag - creatorsToTag.length - 1 > 1
                    ? "people"
                    : "person"
                }}
                left to tag.
              </p>
            </template>
            <template #help>
              <p class="text-xs pt-2">
                For any questions, please refer to our support article on
                <a
                  href="https://support.favorite.ly/creators.html#uploaded-media-content"
                  target="_blank"
                  class="underline"
                >
                  uploaded content
                </a>
                . You can also email us at
                <a href="mailto:support@favorite.ly">support@favorite.ly</a>
                .
              </p>
            </template>
            <UserSearch
              v-model="creatorsToTag"
              user-search-url="/api/users/search"
              class="py-1"
            />
          </UFormGroup>
        </div>
      </div>

      <div class="w-full flex flex-row items-center justify-end gap-1 z-10 p-4">
        <div
          v-for="tag in tags.slice(0, 10)"
          :key="tag"
          class="py-2 px-2.5 rounded-full bg-black/80 text-xs"
        >
          {{ tag.toUpperCase() }}
        </div>
      </div>
    </div>
  </UModal>
</template>

<script setup>
  const props = defineProps({
    media: {
      type: Object,
      required: true,
    },

    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  })

  const detailsShowing = ref(false)

  const isImage = computed(() => {
    return props.media.type == "image"
  })

  const isVideo = computed(() => {
    return props.media.type == "video"
  })

  const approved = computed(() => {
    return ["approved", "manuallyreviewed"].includes(
      props.media.status?.toLowerCase(),
    )
  })

  const rejected = computed(() => {
    return props.media.status?.toLowerCase() == "rejected"
  })

  const pending = computed(() => {
    return props.media.status?.toLowerCase() == "processed"
  })

  const userTagsNeeded = computed(() => {
    return props.media.status?.toLowerCase() == "usertagsneeded"
  })

  const emit = defineEmits([
    "preview",
    "edit",
    "selected",
    "unselected",
    "toggle-selection",
    "update-medium",
  ])

  const toggleSelection = (e) => {
    if (approved.value || props.media.status == "Processed") {
      emit("toggle-selection")
      e.preventDefault()
    }
  }

  const videoPlayer = ref(null)
  const videoPlayerPlaying = ref(false)
  const fullscreenPreview = ref(null)

  const toggleDetails = () => {
    videoPlayerPlaying.value = false
    if (videoPlayer.value) {
      videoPlayer.value.pause()
    }
    emit("preview", props.media)
    // detailsShowing.value = !detailsShowing.value
  }
  const editMediaFromFullscreen = () => {
    toggleDetails()
    emit("edit")
  }
  const toggleVideoPlay = () => {
    if (videoPlayerPlaying.value) {
      videoPlayerPlaying.value = false
      videoPlayer.value.pause()
    } else {
      videoPlayerPlaying.value = true
      videoPlayer.value.play()
    }
  }

  const isSfw = computed(() => {
    return !props.media.internalTags
      .map((x) => x.toLowerCase())
      .includes("nsfw")
  })

  const classTag = computed(() => {
    return isSfw.value ? "sfw" : "nsfw"
  })

  const tags = computed(() => {
    return [classTag.value, ...props.media.tags]
  })

  const config = useRuntimeConfig()
  const creatorsToTag = ref([])
  const numberOfFacesToTag = ref(props.media.numberOfFaces - 1)

  watch(
    () => creatorsToTag.value,
    (newValue) => {
      if (newValue.length >= numberOfFacesToTag.value) {
        tagUsers()
      }
    },
  )

  const tagUsers = async () => {
    try {
      const response = await $api(
        `${config.public.API_URL}/api/media/${props.media.id}/tag_creators`,
        {
          method: "POST",
          body: {
            media: {
              tagged_usernames: creatorsToTag.value.map((c) => c.username),
            },
          },
        },
      )
      emit("update-medium", response)
    } catch (error) {
      console.log(error)
    }
  }
</script>
