<template>
  <div class="w-full rounded-lg p-2" :class="bordered ? borderStyles : ''">
    <!-- Scheduled Banner -->
    <div
      v-if="scheduled && showScheduled"
      class="scheduled-banner favoritely-flip-up"
    >
      <div class="w-full flex flex-row justify-start items-center gap-2">
        <font-awesome-icon
          icon="fa-light fa-calendar"
          size="sm"
          class="text-pink-600 dark:text-pink-500"
        />
        <span class="dark:text-[#555F63]">Scheduled for</span>
        <strong>{{ format(scheduled, "MMM d, hh:mm a") }}</strong>
      </div>
      <Button theme="ghost" :iconOnly="true" @click="clearScheduled">
        <font-awesome-icon icon="fa-solid fa-xmark" class="fa-fw" />
      </Button>
    </div>

    <!-- TOOLBARS -->
    <div
      v-if="userStore && userStore.approvedCreator"
      id="wysiwyg-toolbar"
      class="flex flex-row justify-between items-center"
    >
      <div
        id="toolbar-leftside"
        class="flex flex-row justify-start items-center"
      >
        <!-- PLUGIN TOOLS: Emoji Picker -->
        <UPopover
          v-model:open="showEmojiPicker"
          :popper="{ placement: 'auto' }"
          :ui="popperStyles"
          overylay
        >
          <UTooltip text="Emoji Picker">
            <Button
              theme="ghost"
              :iconOnly="true"
              shape="square"
              size="md"
              class="btn-txt"
            >
              <font-awesome-icon
                icon="fa-light fa-smile"
                size="lg"
                class="text-[#EA1662] dark:text-[#F2699B]"
              />
            </Button>
          </UTooltip>
          <template #panel="{ close }">
            <div class="flex flex-row items-center gap-2 p-4">
              <a
                class="transition transition-all hover:text-2xl hover:cursor-pointer"
                @click="onEmojiSelect('❤️')"
              >
                ❤️
              </a>
              <a
                class="transition transition-all hover:text-2xl hover:cursor-pointer"
                @click="onEmojiSelect('👍')"
              >
                👍
              </a>
              <a
                class="transition transition-all hover:text-2xl hover:cursor-pointer"
                @click="onEmojiSelect('😘')"
              >
                😘
              </a>
              <a
                class="transition transition-all hover:text-2xl hover:cursor-pointer"
                @click="onEmojiSelect('🤯')"
              >
                🤯
              </a>
              <a
                class="transition transition-all hover:text-2xl hover:cursor-pointer"
                @click="onEmojiSelect('🥵')"
              >
                🥵
              </a>
            </div>
          </template>
        </UPopover>

        <!-- PLUGIN TOOLS: Uploader -->
        <UTooltip
          v-if="pluginGroups[pluginGroup].includes('uploader')"
          text="Upload Media"
        >
          <FileUploader
            ref="fileUpload"
            class="text-xs sm:text-sm"
            size="sm"
            :multiple="true"
            :maxUploadSizeInMegabytes="5"
            :accepts="['image/*', 'video/*', '.mov', '.mkv', '.wmv']"
            :creationDate="new Date().toJSON()"
            :initial-tags="[]"
            icon="fa-light fa-image"
            iconStyles="text-[#EA1662] dark:text-[#F2699B]"
            trigger="toolbar"
            :useProfileCheck="true"
            @incomplete-profile="completeYourProfileShowing = true"
            @upload-complete="handleUploadComplete"
          />
        </UTooltip>

        <!-- PLUGIN TOOLS: Date Picker -->
        <UTooltip
          v-if="pluginGroups[pluginGroup].includes('scheduler')"
          text="Schedule Post"
        >
          <SchedulePicker
            theme="ghost"
            size="xl"
            icon="fa-light fa-calendar"
            shape="square"
            @set-datetime="scheduled = $event"
            @clear-datetime="scheduled = null"
          />
        </UTooltip>

        <!-- PLUGIN TOOLS: Media Manager -->
        <UTooltip
          v-if="pluginGroups[pluginGroup].includes('media')"
          text="Media Manager"
        >
          <Button
            @click="handleShowMediaManager"
            theme="ghost"
            :iconOnly="true"
            shape="square"
            size="md"
            class="btn-txt"
          >
            <font-awesome-icon
              icon="fa-light fa-cloud"
              size="lg"
              class="text-[#EA1662] dark:text-[#F2699B]"
            />
          </Button>
        </UTooltip>
      </div>

      <div
        id="toolbar-rightside"
        class="flex flex-row justify-start items-center"
      >
        <!-- PLUGIN TOOLS: Recipent Picker -->
        <UTooltip
          v-if="pluginGroups[pluginGroup].includes('recipients')"
          text="Recipient List Manager"
        >
          <Button
            @click="handleShowRecipientListManager"
            theme="ghost"
            :iconOnly="true"
            shape="square"
            size="md"
            class="btn-txt"
          >
            <font-awesome-icon
              icon="fa-light fa-people-group"
              size="lg"
              class="text-[#EA1662] dark:text-[#F2699B]"
            />
            <span>{{ recipientsCount }}</span>
            <span class="capitalize">Recipients</span>
          </Button>
        </UTooltip>

        <!-- CLEAR INPUT -->
        <Button
          v-if="
            postInput?.innerHTML &&
            !pluginGroups[pluginGroup].includes('recipients')
          "
          @click="clearInput"
          theme="ghost"
          :iconOnly="true"
          shape="square"
          size="md"
          class="btn-txt ml-auto"
        >
          <!-- <font-awesome-icon icon="fa-solid fa-xmark" size="lg" /> -->
          clear
        </Button>
      </div>
    </div>
    <UDivider v-if="userStore.approvedCreator" />

    <!-- INPUT -->
    <div
      v-once
      id="wysiwyg-input"
      ref="postInput"
      @input="onInput"
      v-html="innerValue"
      contenteditable="true"
      :placeholder="placeholder"
      class="wysiwyg-output outline-none"
    />

    <!-- MEDIA THUMBS -->
    <div v-if="media.length" class="flex flex-col gap-4">
      <div class="flex flex-row flex-wrap items-center gap-2">
        <MediaSelectedThumb
          v-for="m in media"
          :locked="m.locked"
          :key="m.id"
          :media="m"
          @click="toggleMediaLock(m)"
          @remove="handleRemoveMedia(m)"
        />
        <Button
          theme="tertiary"
          :iconOnly="true"
          shape="square"
          class="!h-[62px]"
          @click="showMediaManager = true"
        >
          <font-awesome-icon icon="fa-light fa-plus" />
        </Button>
      </div>
      <div
        v-if="media.length"
        class="text-xs favoritely-fade-in text-[#595858] dark:text-[#555F63]"
      >
        Tap media to unlock for free, or lock for the price you set.
        <strong>NSFW</strong>
        content is always locked.
      </div>
    </div>

    <!-- MEDIA MANAGER -->
    <UModal v-model="showMediaManager" fullscreen :ui="modalUi">
      <MediaManager
        :selected-media="mappedMedia"
        :show-add-to-post-button="true"
        :show-add-to-message-button="false"
        @attach-media="handleAttachMedia"
        @navigate-back="showMediaManager = false"
      />
    </UModal>

    <!-- Modal: Complete your profile -->
    <CompleteProfileModal
      title="Before you post let's complete your profile"
      :open="completeYourProfileShowing"
      @close="completeYourProfileShowing = false"
    />
  </div>

  <!-- UPLOAD PROGRESS -->
  <MediaLibraryUploads
    v-if="activeUploads.length"
    :uploads="activeUploads"
    class="min-h-32 max-h-[40%] overflow-y-auto no-scrollbar"
  />
</template>

<script setup>
  import { sub, format, isSameDay } from "date-fns"
  const { profileComplete } = useProfileCompletion()
  const resetWysiwyg = inject("resetWysiwyg")

  const props = defineProps({
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "What's on your mind?",
    },
    toolbarGroup: {
      type: String,
      default: "none",
    },
    pluginGroup: {
      type: String,
      default: "none",
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    schedule: {
      type: String,
      default: null,
    },
    media: {
      type: Array,
      default: [],
    },
    message: {
      type: String,
      default: null,
    },
    resetForm: {
      type: Boolean,
      default: false,
    },
    recipients: {
      type: String,
      default: null,
    },
    recipientsCount: {
      type: Number,
      default: 0,
    },
    showScheduled: {
      type: Boolean,
      default: true,
    },
  })

  const emit = defineEmits([
    "clear",
    "input",
    "media",
    "schedule",
    "incomplete-profile",
    "show-recipient-list-manager",
  ])

  const userStore = useUserStore()

  const scheduled = ref(props.schedule)
  const media = ref(props.media)
  const postInput = ref(null)
  const showMediaManager = ref(false)
  const showEmojiPicker = ref(false)
  const completeYourProfileShowing = ref(false)
  const innerValue = ref(props.value)
  const fileUpload = ref(null)
  const activeUploads = ref([])

  const onInput = (event) => {
    if (profileComplete.value) {
      emit("input", event.target.innerHTML)
    } else {
      completeYourProfileShowing.value = true
    }
  }

  watch(
    () => props.resetForm,
    (value) => {
      postInput.value.innerHTML = ""
      innerValue.value = ""
      media.value = []
      scheduled.value = null
      resetWysiwyg.value = false
    },
  )

  watch(
    () => innerValue.value,
    (value) => {
      postInput.value.innerHTML = value
      innerValue.value = value
    },
  )

  watch(
    () => scheduled.value,
    (value) => {
      emit("schedule", value)
    },
  )

  watch(
    () => media.value,
    (value) => {
      emit("media", value)
    },
  )

  const mappedMedia = computed(() => {
    return media.value.map((m) => {
      return {
        id: m.id,
        signedThumbnailUrl: m.signedThumbnailUrl,
        locked: m.locked,
        nsfw: m.nsfw,
      }
    })
  })

  const modalUi = {
    fullscreen: "m-[1rem] h-[90vh] w-[90vw] rounded-2xl !bg-transparent",
    shadow: "shadow-none",
    container: "items-start md:items-center",
    overlay: {
      background:
        "bg-gray-200/75 dark:bg-black backdrop-filter backdrop-blur-3xl",
    },
  }

  const toggleMediaLock = (item) => {
    if (!item.nsfw) {
      item.locked = !item.locked
    }
    emit("media", media.value)
  }

  const clearInput = () => {
    if (profileComplete.value) {
      postInput.value.innerHTML = ""
      innerValue.value = ""
      media.value = []
      scheduled.value = null
      emit("clear")
    } else {
      emit("incomplete-profile")
    }
  }

  const clearScheduled = () => {
    scheduled.value = null
  }

  const onEmojiSelect = (emoji) => {
    if (profileComplete.value) {
      postInput.value.innerText = `${postInput.value.innerText}${emoji} `
      postInput.value.focus()
      const range = document.createRange()
      range.selectNodeContents(postInput.value)
      range.collapse()
      const sel = document.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)

      emit("input", postInput.value.innerText)
    } else {
      completeYourProfileShowing.value = true
    }
  }

  const handleShowMediaManager = () => {
    if (profileComplete.value) {
      showMediaManager.value = true
    } else {
      completeYourProfileShowing.value = true
    }
  }

  const handleShowRecipientListManager = () => {
    emit("show-recipient-list-manager")
  }

  const handleAttachMedia = (type, data) => {
    const mediaToAttach = []
    for (let i = 0; i < data.length; i++) {
      if (data[i].nsfw) {
        data[i]["locked"] = true
      } else {
        data[i]["locked"] = false
      }
      mediaToAttach.push(data[i])
    }
    media.value = mediaToAttach
    showMediaManager.value = false
  }

  const handleUploadComplete = (response) => {
    setTimeout(() => {
      media.value.push({
        id: response.id,
        signedThumbnailUrl: response.signedThumbnailUrl,
        signedUrl: response.signedUrl,
        locked: false,
        newUpload: true,
        previewUrl: response.previewUrl,
      })
    }, 1000)
  }

  const handleRemoveMedia = (m) => {
    media.value = media.value.filter((media) => media.id !== m.id)
  }

  const pluginGroups = {
    none: [],
    basic: [],
    standard: ["media", "uploader", "scheduler"],
    dm: ["media", "uploader", "scheduler", "recipients"],
    full: ["media", "scheduler"],
  }

  const borderStyles = "border-2 dark:border-[#f26998]"

  const trackNewUpload = (upload) => {
    activeUploads.value.push({
      id: upload.id,
      name: upload.uploadedFile.name,
      progress: upload.progress(),
      previewUrl: upload.previewUrl,
      type: upload.uploadedFile.type,
    })
  }
  provide("trackNewUpload", trackNewUpload)

  const updateUploadProgress = (upload) => {
    const index = activeUploads.value.findIndex((item) => item.id === upload.id)
    activeUploads.value[index].progress = upload.progress()
  }
  provide("updateUploadProgress", updateUploadProgress)

  const uploadComplete = (upload) => {
    setTimeout(() => {
      const index = activeUploads.value.findIndex(
        (item) => item.id === upload.id,
      )
      activeUploads.value.splice(index, 1)
    }, 3000)
  }
  provide("uploadComplete", uploadComplete)

  const popperStyles = {
    overlay: {
      base: "fixed inset-0 transition-opacity z-50",
      background: "bg-gray-200/25 dark:bg-gray-800/25",
      transition: {
        enterActiveClass: "ease-out duration-200",
        enterFromClass: "opacity-0",
        enterToClass: "opacity-100",
        leaveActiveClass: "ease-in duration-150",
        leaveFromClass: "opacity-100",
        leaveToClass: "opacity-0",
      },
    },
  }
</script>

<style lang="scss">
  #wysiwyg-input {
    @apply text-sm leading-[22.5px] min-h-[55.5px];
  }

  #wysiwyg-input:empty:before {
    @apply block text-[#555F63] content-[attr(placeholder)];
  }

  .scheduled-banner {
    @apply bg-[#FBEFF3] pl-4 py-1 rounded-lg flex justify-between;
    @apply dark:bg-[#2B3236];
  }

  .btn-txt {
    @apply text-[#595858] dark:text-[#555F63] hover:text-black dark:hover:text-[#9ca3af];
  }

  .editor-button {
    @apply rounded-lg px-3 py-2 mb-3 mr-3;
  }

  .editor-button:hover {
    @apply border-green-300;
  }

  .wysiwyg-output {
    @apply p-4 pl-0 rounded-lg;
  }

  .wysiwyg-output h1 {
    @apply text-[1.4rem] font-bold pb-4;
  }

  .wysiwyg-output p {
    @apply pb-4;
  }
</style>
