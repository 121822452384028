<template>
  <UAvatar v-if="!urlIsVideo" :size="size" :src="src" />
  <video
    v-else
    class="aspect-1 rounded-full bg-cover object-cover bg-center bg-no-repeat"
    autoplay
    loop
    muted
    playsinline
    disablePictureInPicture
    :class="[sizeClasses]"
    :poster="src"
  >
    <source :src="src" type="video/mp4" />
  </video>
</template>

<script setup lang="ts">
  const props = defineProps({
    showIndicator: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: "md",
    },

    src: {
      type: String,
      default:
        "https://favoritely-assets.s3.us-west-1.amazonaws.com/staging/assets/photo_placeholders/250x250.png",
    },
  })

  const urlIsVideo = computed(() => {
    return props.src?.includes(".mp4")
  })

  const sizeMap = {
    "3xs": "h-[4px] min-w-[4px] text-[4px] p-px",
    "2xs": "h-[5px] min-w-[5px] text-[5px] p-px",
    xs: "h-6 min-w-[0.375rem] text-[6px] p-px",
    sm: "h-8 min-w-[0.5rem] text-[7px] p-0.5",
    md: "h-12 min-w-[0.625rem] text-[8px] p-0.5",
    lg: "h-14 min-w-[0.75rem] text-[10px] p-0.5",
    xl: "h-16 min-w-[0.875rem] text-[11px] p-1",
    "2xl": "h-20 min-w-[1rem] text-[12px] p-1",
    "3xl": "h-24 text-[14px] p-1",
  }

  const sizeClasses = computed(() => {
    return sizeMap[props.size]
  })
</script>

<style lang="scss" scoped>
  img {
    @apply h-8 rounded-full;

    aspect-ratio: 1/1;

    &.sm {
      @apply h-4;
    }

    &.lg {
      @apply h-16;
    }
  }

  .indicator {
    @apply absolute right-0 bottom-0;
    @apply outline outline-2 outline-white dark:outline-gray-900;
    @apply bg-lime-500 rounded-full h-2 w-2;

    &.xs {
      @apply h-1 w-1 right-[0.1rem] bottom-[0.1rem] outline-2;
    }

    &.sm {
      @apply h-1 w-1 outline-2;
    }

    &.lg {
      @apply h-3 w-3 right-1 bottom-1 outline-4;
    }
  }
</style>
