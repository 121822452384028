<template>
  <canvas ref="canvas"></canvas>
</template>

<script setup>
  import { decodeBlurHash } from "fast-blurhash"

  const props = defineProps({
    blurHash: String,
  })

  const tryDecodeBlurHash = (blurHash, width, height) => {
    if (blurHash == "") {
      const samples = [
        "LEHLk~WB2yk8pyo0adR*.7kCMdnj",
        "LGF5]+Yk^6#M@-5c,1J5@[or[Q6.",
        "L6PZfSi_.AyE_3t7t7R**0o#DgR4",
        "LKN]Rv%2Tw=w]~RBVZRi};RPxuwH",
        "LDLW*CqZ010z~WrBrFSJ.AnyIT_3",
      ]
      blurHash = samples[Math.floor(Math.random() * samples.length)]
    }

    try {
      return decodeBlurHash(blurHash, width, height)
    } catch (e) {
      decodeBlurHash(
        samples[Math.floor(Math.random() * samples.length)],
        width,
        height,
      )
    }
  }

  const resizeCanvas = () => {
    if (canvas.value == null) return

    const container = canvas.value.parentElement
    canvas.value.width = container.clientWidth
    canvas.value.height = container.clientHeight

    const ctx = canvas.value.getContext("2d")
    const pixels = tryDecodeBlurHash(
      props.blurHash,
      container.clientWidth,
      container.clientHeight,
    )

    const imageData = new ImageData(
      pixels,
      container.clientWidth,
      container.clientHeight,
    )
    ctx.putImageData(imageData, 0, 0)
  }

  const handleVisibilityChange = (isVisible) => {
    if (isVisible) {
      resizeCanvas()
      const resizeObserver = new ResizeObserver(resizeCanvas)
      resizeObserver.observe(canvas.value.parentElement)
      window.addEventListener("resize", resizeCanvas)
    }
  }

  const canvas = ref(null)
  const canvasIsVisible = useElementVisibility(canvas)

  watch(canvasIsVisible, handleVisibilityChange)
</script>
