<template>
  <SplitView :show-sidebar="!currentSelection">
    <!-- Left: Sidebar-->
    <template #left>
      <MediaLibrarySidebar
        v-if="!globalListsPending && !tagListsPending"
        :restriction="restriction"
        :global-lists="globalLists"
        :tag-lists="tagLists"
        :current-selection-key="currentSelection ? currentSelection.key : null"
        @selected="updateSelection"
        @sort-filter-changed="updateListSortFilter"
        @tags-deleted="refresh"
        @navigate-back="$emit('navigate-back')"
      />
    </template>

    <!-- Right: Content -->
    <template #right>
      <!-- Mobile -->

      <!-- Desktop -->
      <div class="flex flex-grow flex-col gap-1">
        <div class="flex flex-grow overflow-y-auto no-scrollbar">
          <MediaLibraryBrowser
            :restriction="restriction"
            :allow-multi-select="allowMultiSelect"
            :allowed-media-type="allowedMediaType"
            :label="currentSelection ? currentSelection.label : 'All Uploads'"
            :list-initial-tags="
              currentSelection ? currentSelection.initialTags : []
            "
            :key="currentSelection ? currentSelection.key : 'All Uploads'"
            :media="
              !pending && currentSelection && media?.items ? media.items : null
            "
            :sorting-order="sortingOrder"
            :media-filter-type="mediaTypeFilter"
            :allow-creation-in-list="
              currentSelection ? currentSelection.allowCreationInList : true
            "
            :show-add-to-message-button="showAddToMessageButton"
            :show-add-to-post-button="showAddToPostButton"
            :show-set-profile-photo-button="showSetProfilePhotoButton"
            :show-set-profile-background-button="showSetProfileBackgroundButton"
            :show-upload-button="showUploadButton"
            :tag-lists="tagLists"
            :selected-media="selectedMedia"
            @change-filter-type="updateMediaFilterType"
            @refresh-media="refresh"
            @sorting-order-change="updateSortingOrder"
            @attach-media="bubbleUpAttachMedia"
            @update-medium="updateMedium"
          />
        </div>

        <MediaLibraryUploads
          v-if="activeUploads.length"
          :uploads="activeUploads"
          class="min-h-32 max-h-[40%] overflow-y-auto no-scrollbar"
        />
      </div>
    </template>
  </SplitView>
</template>

<script setup>
  const route = useRoute()

  let areaName = null
  switch (route.name) {
    case "home":
      areaName = "media"
    case "calendar":
      areaName = "media"
    case "profile":
      areaName = "media"
    case "purchases":
      areaName = "purchases"
    case "messages":
      areaName = "media"
    case "settings":
      areaName = "media"
    default:
      areaName = "media"
  }

  const props = defineProps({
    allowMultiSelect: {
      type: Boolean,
      default: true,
    },

    allowedMediaType: {
      type: String,
      default: "all",
    },

    showAddToMessageButton: {
      type: Boolean,
      default: true,
    },

    showAddToPostButton: {
      type: Boolean,
      default: true,
    },

    showSetProfilePhotoButton: {
      type: Boolean,
      default: false,
    },

    showSetProfileBackgroundButton: {
      type: Boolean,
      default: false,
    },

    showUploadButton: {
      type: Boolean,
      default: true,
    },

    restriction: {
      type: String,
      default: null,
    },

    selectedMedia: {
      type: Array,
      default: null,
    },
  })

  const emit = defineEmits(["attach-media", "navigate-back"])
  const currentSelection = ref(null)
  const currentSelectionKey = ref(null)
  const sortingOrder = ref("desc")
  const config = useRuntimeConfig()
  const listSortingField = ref("tag")
  const listSortingOrder = ref("asc")

  const mediaTypeFilter = ref("all")
  // const mediaTypeFilter = computed(() => {
  //   return props.allowedMediaType
  // })

  const activeUploads = ref([])

  const trackNewUpload = (upload) => {
    activeUploads.value.push({
      id: upload.id,
      name: upload.uploadedFile.name,
      progress: upload.progress(),
      type: upload.uploadedFile.type,
    })
  }
  provide("trackNewUpload", trackNewUpload)

  const updateUploadProgress = (upload) => {
    const index = activeUploads.value.findIndex((item) => item.id === upload.id)
    activeUploads.value[index].progress = upload.progress()
  }
  provide("updateUploadProgress", updateUploadProgress)

  const uploadComplete = (upload) => {
    setTimeout(() => {
      const index = activeUploads.value.findIndex(
        (item) => item.id === upload.id,
      )
      activeUploads.value.splice(index, 1)
    }, 3000)
  }
  provide("uploadComplete", uploadComplete)

  const {
    pending: globalListsPending,
    data: globalLists,
    refresh: refreshGlobalLists,
  } = await useAuthFetch(
    `${config.public.API_URL}/api/${areaName}/lists/global`,
    {
      query: {
        restriction: props.restriction,
      },
    },
  )

  const {
    pending: tagListsPending,
    data: tagLists,
    refresh: refreshTagLists,
  } = await useAuthFetch(
    `${config.public.API_URL}/api/${areaName}/lists/tags`,
    {
      query: {
        restriction: props.restriction,
        sorting_field: listSortingField,
        sorting_order: listSortingOrder,
      },
    },
  )

  const {
    data: media,
    pending,
    refresh: refreshMedia,
    status,
  } = await useAuthFetch(`${config.public.API_URL}/api/${areaName}`, {
    // TODO: for purchase, this has to be removed
    // immediate: false,
    query: {
      tag: currentSelectionKey,
      type: mediaTypeFilter,
      sorting_order: sortingOrder,
      restriction: props.restriction,
    },
  })

  const updateListSortFilter = (newFilter) => {
    listSortingField.value = newFilter.field
    listSortingOrder.value = newFilter.order
  }

  const clearSelection = () => {
    media.value = null
    currentSelection.value = null
  }

  const updateMediaFilterType = (newFilterType) => {
    mediaTypeFilter.value = newFilterType
    refresh()
  }

  const updateSortingOrder = (newOrder) => {
    sortingOrder.value = newOrder
    refresh()
  }

  const updateSelection = (newSelection) => {
    currentSelection.value = newSelection
    currentSelectionKey.value = newSelection.key

    if (status.value != "pending") {
      refresh()
    }
  }

  const bubbleUpAttachMedia = (type, data) => {
    emit("attach-media", type, data)
  }

  const refresh = () => {
    refreshMedia()
    refreshGlobalLists()
    refreshTagLists()
  }

  const updateMedium = (updatedMedia) => {
    media.value.items = media.value.items.map((m) => {
      if (m.id === updatedMedia.id) {
        return { ...m, ...updatedMedia }
      }
      return m
    })
  }
</script>
