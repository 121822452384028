<template>
  <div class="relative rounded-full pb-4">
    <div
      class="absolute top-2 right-2 w-12 h-12 rounded-full border-[1rem] border-black flex items-center justify-center"
    >
      <button @click="$emit('close')" class="" aria-label="Close">
        <font-awesome-icon
          icon="fa-solid fa-times"
          class="text-white-600 text-xl"
        />
      </button>
    </div>
    <div class="flex flex-col gap-4">
      <div class="p-1">
        <div
          class="w-full h-44 rounded-lg bg-cover bg-center bg-no-repeat"
          :style="`background-image: url('${backgroundUrl}')`"
        />
      </div>
      <div class="px-4 flex flex-col gap-4">
        <IdentityCard size="lg" :user="creator" />
        <UDivider />
        <span class="text-lg">Subscribe to get perks like:</span>
        <div class="flex flex-row items-center gap-2">
          <font-awesome-icon
            icon="fa-light fa-circle-check"
            class="text-pink-600"
          />
          <span class="text-lg">
            <span class="font-bold">Unlock all</span>
            locked profile page posts of this creator
          </span>
        </div>
        <div class="flex flex-row items-center gap-2">
          <font-awesome-icon
            icon="fa-light fa-circle-check"
            class="text-pink-600"
          />
          <span class="text-lg">
            <span class="font-bold">Direct messages</span>
            with this creator
          </span>
        </div>
        <div class="flex flex-row items-center gap-2">
          <font-awesome-icon
            icon="fa-light fa-circle-check"
            class="text-pink-600"
          />
          <span class="text-lg font-bold">Cancel anytime</span>
        </div>
        <UDivider />
        <Button block @click="nextStep">
          Subscribe for
          {{ USDollar.format(subscriptionPrice / 100) }} *
        </Button>
        <span class="text-center text-sm text-cool-400">
          * Additional fees may apply
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useUserStore } from "@/store/user"
  const props = defineProps({
    creator: {
      type: Object,
      required: true,
    },
    paymentAccounts: {
      type: Array,
      required: true,
    },
  })
  const route = useRoute()
  const toast = useToast()
  const userStore = useUserStore()
  const emit = defineEmits(["move-to-step", "close"])
  const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })

  const backgroundUrl = computed(() => {
    return (
      props.creator.backgroundUrl ||
      "https://favoritely-assets.s3.us-west-1.amazonaws.com/staging/assets/photo_placeholders/610x160.png"
    )
  })
  const nextStep = async () => {
    if (userStore.loggedIn) {
      if (props.paymentAccounts.length) {
        emit("move-to-step", {
          step: "subscription-choose-payment-account",
          from: "subscribe-to-creator",
        })
      } else {
        emit("move-to-step", {
          step: "enter-new-card",
          from: "subscription-choose-payment-account",
        })
      }
    } else {
      navigateTo({
        path: "/login",
        query: { redirectBackTo: route.fullPath },
      })
    }
  }

  const subscriptionPrice = computed(() => {
    return (
      props.creator?.subscriptionPriceInCents || props.creator.subscriptionPrice
    )
  })
</script>
