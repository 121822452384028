<template>
  <div @click="openPurchaseModal" class="token-count-with-button">
    <div class="container">
      <div
        class="flex flex-row flex-nowrap justify-center items-center leading-none pr-3 -ml-[3px] md:pl-1 sm:pr-2"
      >
        <font-awesome-layers class="fa-1x fa-stack mr-0.5">
          <font-awesome-icon
            icon="fa-solid fa-circle"
            class="fa-stack-2x text-cool-100 text-sm sm:text-base dark:text-[#2B3236]"
          />
          <font-awesome-icon
            icon="fa-light fa-coins"
            class="fa-stack-1x text-cool-700 text-sm sm:text-base dark:text-[#F2699B]"
            transform="shrink-2"
          />
        </font-awesome-layers>
        <span class="font-semibold text-sm sm:text-base">
          {{ $numberToHuman(userStore.tokenBalance) }}
        </span>
        <span v-if="!isMobile" class="text-sm">&nbsp;Tokens</span>
      </div>
      <Button
        v-if="userStore?.loggedIn"
        theme="primary"
        class="mr-0.5 !hidden md:!flex"
        size="sm"
      >
        Buy Tokens
      </Button>
      <Button v-else theme="primary" size="xs" class="!hidden md:!flex">
        Sign up
      </Button>
    </div>
  </div>

  <UModal
    v-model="loginShowing"
    :ui="{ container: 'items-start md:items-center' }"
  >
    <div class="p-8 pb-16">
      <LoginForm @logged-in="loginShowing = false" />
    </div>
  </UModal>
</template>

<script setup>
  import { useUserStore } from "~/store"

  const toast = useToast()
  const userStore = useUserStore()
  const isMobile = useMediaQuery("(max-width: 767px)")
  const tokenPurchaseModalOpen = inject("tokenPurchaseModalOpen")

  const { $bus } = useNuxtApp()
  $bus.$on("token-count-updated", ({ payload }) => {
    userStore.updateTokenBalance(payload)
  })

  const props = defineProps({
    user: {
      type: Object,
    },
    buttonText: {
      type: String,
      default: "Buy Tokens",
      required: false,
    },

    button: {
      type: Boolean,
      default: true,
    },
  })

  const loginShowing = ref(false)

  const openPurchaseModal = () => {
    if (userStore.loggedIn) {
      if (userStore.emailConfirmed) {
        tokenPurchaseModalOpen.value = true
      } else {
        toast.add({
          title: "Please confirm your email before buying tokens",
          type: "error",
        })
      }
    } else {
      loginShowing.value = true
    }
  }
</script>

<style lang="scss" scoped>
  .token-count-with-button {
    @apply flex flex-row items-center justify-end gap-2 cursor-pointer;
  }

  .container {
    @apply flex flex-row justify-between items-center gap-4 rounded-full bg-white text-black dark:bg-[#1B2022] dark:text-white md:w-[340px];
    padding: 6px 7px 6px 3px;
  }
</style>
