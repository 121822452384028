<template>
  <div class="flex flex-col">
    <FormWysiwyg
      v-model="form.text"
      :value="form.text"
      :schedule="form.scheduled"
      :media="form.media"
      :resetForm="resetWysiwyg"
      toolbarGroup="basic"
      pluginGroup="standard"
      @input="handleInput"
      @media="handleMedia"
      @schedule="handleScheduled"
      @clear="resetForm"
      @incomplete-profile="handleIncompleteProfile"
      placeholder="Create a new post..."
    />

    <Errorbox :errors="form.errors" />

    <div class="flex flex-row items-center justify-end mb-2">
      <div class="flex flex-row items-center gap-2">
        <PriceButton
          v-if="showPriceButton"
          primaryLabel="Set Price"
          secondaryLabel="Schedule Post"
          theme="primary"
          size="md"
          :mode="mode"
          :tokenCount="form.price"
          :showInput="showPriceForm"
          :error="form.errors"
          @price-set="handlePriceSet"
          @post="handleSubmitPost"
        />
        <Button
          v-if="!showPriceButton"
          :loading="loading"
          @click="handleSubmitPost"
          theme="primary"
        >
          {{ postButtonLabel }}
        </Button>
      </div>
    </div>
  </div>

  <!-- Modal: Become a creator -->
  <UModal
    v-model="becomeACreatorShowing"
    :ui="{ container: 'items-start md:items-center' }"
  >
    <div class="flex flex-col gap-3 px-4 py-6">
      <h2 class="text-xl">
        Be the next and only
        <br class="hidden md:block lg:block" />
        <span class="text-primary-600">Favorite</span>
        for your fans
      </h2>

      <p class="mb-2">
        Start earning money by creating content for your fans. Get started by
        verifying your account.
      </p>

      <div class="text-center">
        <Button
          size="xl"
          class="text-lg"
          @click="navigateTo('/settings/verification')"
        >
          Get Started
        </Button>
      </div>
    </div>
  </UModal>

  <!-- Modal: Complete your profile -->
  <CompleteProfileModal
    title="Before you post let's complete your profile"
    :open="completeYourProfileShowing"
    @close="completeYourProfileShowing = false"
  />
</template>

<script setup>
  const { profileComplete } = useProfileCompletion()

  const props = defineProps({
    mode: {
      type: String,
      default: "create",
    },
    scheduled: {
      type: String,
      default: null,
    },
    post: {
      type: Object,
      default: null,
    },
  })

  const emit = defineEmits(["broadcast-feed-update", "post-updated"])

  const userStore = useUserStore()
  const becomeACreatorShowing = ref(false)
  const completeYourProfileShowing = ref(false)
  const showPriceForm = ref(false)
  const loading = ref(false)

  const resetWysiwyg = ref(false)
  provide("resetWysiwyg", resetWysiwyg)

  const mappedMedia = computed(() => {
    return props.post?.media.map((m) => {
      return {
        ...m,
        id: m.mediaId,
      }
    })
  })

  const form = reactive({
    errors: [],
    pending: false,
    text: props.post?.text || "",
    price: props.post?.tokenCount || null,
    scheduled: props.scheduled,
    media: mappedMedia.value || [],
  })

  const resetForm = () => {
    form.text = ""
    form.price = null
    form.scheduled = null
    form.media = []
    form.errors = []
    form.pending = false
    showPriceForm.value = false
    resetWysiwyg.value = true
  }

  const showPriceButton = computed(() => {
    if (form.media.length) {
      const lockedMedia = form.media.filter((m) => m.locked)
      if (lockedMedia.length || form.price) {
        return true
      }
    } else {
      return false
    }
  })

  const postButtonLabel = computed(() => {
    if (props.mode === "edit") {
      return form.scheduled ? "Update Schedule Post" : "Update Post"
    } else {
      return form.scheduled ? "Schedule Post" : "Post"
    }
  })

  const handleInput = (text) => {
    form.text = text
  }

  const handlePriceSet = (value) => {
    form.price = value
  }

  const handleMedia = (data) => {
    form.media = data
    showPriceForm.value = true
  }

  const handleScheduled = (schedule) => {
    form.scheduled = schedule
  }

  const handleIncompleteProfile = () => {
    completeYourProfileShowing.value = true
  }

  const handleSubmitPost = async () => {
    if (profileComplete.value) {
      await submitPost()
    } else {
      handleIncompleteProfile()
    }
  }

  const submitPost = async () => {
    form.errors = []

    if (userStore.approvedCreator) {
      if (!form.text && !form.media.length) return

      let hasLockedMedia =
        form.media?.filter((m) => m.locked).filter(Boolean).length || false

      if (hasLockedMedia && !form.price)
        form.errors.push(
          "Locked media requires you set a token price for your post.",
        )
      if (!form.text) form.errors.push("Add a message to your post.")
      if (form.errors.length) return

      try {
        loading.value = true
        form.pending = true

        const method = props.mode === "create" ? "POST" : "PUT"
        const config = useRuntimeConfig()
        const scheduledAt = form.scheduled
          ? new Date(form.scheduled)
          : new Date()

        const payloadMedia = form.media.map((m) => {
          return {
            id: m.id,
            locked: m?.locked || false,
            signedThumbnailUrl: m.signedThumbnailUrl,
          }
        })

        const payload = {
          post: {
            text: form.text,
            tokenCount: +form.price,
            scheduledAt: scheduledAt,
            media: payloadMedia,
          },
        }

        if (props.mode === "edit" && props.post?.id) {
          payload.post.id = props.post.id
        }

        const endpoint =
          props.mode === "create"
            ? `${config.public.API_URL}/api/posts`
            : `${config.public.API_URL}/api/posts/${props.post.id}`

        const { error, data } = await useAuthFetch(endpoint, {
          method: method,
          body: payload,
        })

        if (error.value) {
          console.log("error: ", error.value)
          form.errors = useErrorFormatter(error.value.data)
        } else {
          props.mode === "edit"
            ? emit("post-updated", data)
            : emit("broadcast-feed-update", data)

          resetForm()
        }
      } catch (error) {
        if (error.message) form.errors = error.message
      } finally {
        const message =
          props.mode === "edit"
            ? "Post has been updated."
            : "Post has been created."
        const toast = useToast()
        toast.add({ title: message })
        form.pending = false

        setTimeout(() => {
          loading.value = false
        }, 500)
      }
    } else {
      becomeACreatorShowing.value = true
    }
  }
</script>
