<template>
  <PurchaseModalStepsSubscribeToCreator
    v-if="currentStep == 'subscribe-to-creator' && !paymentAccountsPending"
    :creator="creator"
    :payment-accounts="paymentAccounts.items"
    @close="$emit('close')"
    @move-to-step="moveToStep"
  />

  <Modal
    v-if="currentStep != 'subscribe-to-creator' && !paymentAccountsPending"
    :title="stepLabel"
    @close="$emit('close')"
  >
    <PurchaseModalStepsSelectTokenAmount
      v-if="currentStep == 'select-token-amount' && !paymentAccountsPending"
      :payment-accounts="paymentAccounts.items"
      :initial-step="initialStep"
      @close="$emit('close')"
      @move-to-step="moveToStep"
    />
    <PurchaseModalStepsSubscriptionChoosePaymentAccount
      v-if="
        currentStep == 'subscription-choose-payment-account' &&
        !paymentAccountsPending
      "
      :creator="creator"
      :payment-accounts="paymentAccounts.items"
      :initial-step="initialStep"
      :loading="loading"
      @close="$emit('close')"
      @move-to-step="moveToStep"
      @subscription-purchased="$emit('subscription-purchased')"
    />
    <PurchaseModalStepsEnterNewCard
      v-if="currentStep == 'enter-new-card' && !paymentAccountsPending"
      :next-step="previousStep"
      :initial-step="initialStep"
      @close="$emit('close')"
      @move-to-step="moveToStep"
      @refresh-payment-accounts="paymentAccountsRefresh"
    />
    <PurchaseModalStepsUpdateCard
      v-if="currentStep == 'update-card' && !paymentAccountsPending"
      @close="$emit('close')"
      @move-to-step="moveToStep"
    />
  </Modal>
</template>

<script>
  export default { name: "PurchaseModal" }
</script>

<script setup>
  import { useUserStore } from "@/store/user"

  const props = defineProps({
    creator: {
      type: Object,
      default: null,
    },

    initialStep: {
      type: String,
      required: true,
    },
  })

  const emit = defineEmits(["close", "subscription-purchased"])

  const config = useRuntimeConfig()
  const userStore = useUserStore()
  const toast = useToast()

  const loading = ref(false)
  const currentStep = ref(props.initialStep)
  const previousStep = ref(null)
  const steps = [
    {
      key: "subscribe-to-creator",
      label: "Subscribe to Creator",
    },
    {
      key: "select-token-amount",
      label: "Purchase Tokens",
    },
    {
      key: "subscription-choose-payment-account",
      label: "Subscription",
    },
    {
      key: "enter-new-card",
      label: "New Credit / Debit Card",
    },
    {
      key: "update-card",
      label: "Update Card",
    },
  ]

  const availablePaymentMethods = ["card"]
  provide("availablePaymentMethods", availablePaymentMethods)

  const stepLabel = computed(() => {
    return steps.find((s) => s.key === currentStep.value).label
  })

  const moveToStep = (newStep) => {
    currentStep.value = newStep.step
    previousStep.value = newStep.from
  }
  provide("moveToStep", moveToStep)

  const {
    pending: paymentAccountsPending,
    data: paymentAccounts,
    refresh: paymentAccountsRefresh,
  } = useAuthFetch(`${config.public.API_URL}/api/payment_accounts`)
  provide("paymentAccounts", paymentAccounts)
  provide("paymentAccountsRefresh", paymentAccountsRefresh)

  const purchaseSubscription = async () => {
    loading.value = true
    const { error } = await useAuthFetch(
      `${config.public.API_URL}/api/purchases/buy_subscription`,
      {
        method: "POST",
        body: JSON.stringify({
          subscription: {
            payment_account_id: form.data.paymentMethodSelected.value,
            creator_username: props.creator.username,
          },
        }),
      },
    )

    if (error.value) {
      loading.value = false
      console.error(error.value)
    } else {
      loading.value = false
      userStore.getData()
      toast.add({ title: "Subscription purchased!" })
      emit("subscription-purchased")
      emit("close")
    }
  }
  provide("purchaseSubscription", purchaseSubscription)

  const form = reactive({
    data: {
      agreeToTerms: false,
      paymentType: null,
      paymentMethodSelected: null,
      creatorUsername: props.creator?.username || null,
      primary: null,
    },
    errors: {
      agreeToTerms: null,
      paymentMethodSelected: null,
    },
  })
  provide("form", form)
</script>
